import { instance } from '@/utils/http.js'

// 获取考试列表
export const getExamList = function (params) {
  return instance({
    url: '/api/v1/test/list',
    method: 'get',
    params
  })
}

// 获取考试类型 
export const getTypeList = function (params) {
  return instance({
    url: '/api/v1/combination/paper_modules',
    method: 'get',
    params
  })
}

// 用户试卷选择 
export const getPaperList = function (params) {
  return instance({
    url: '/api/v1/combination/user_papers',
    method: 'get',
    params
  })
}

// 根据试卷id获取主观题题号
export const getQuestionNumber = (params) => {
  return instance({
    url: '/api/v1/test/edu_paper_detail',
    method: 'get',
    params
  })
}

// 获取科目选择教师
export const getTeacherList = (params) => {
  return instance({
    url: '/api/v1/test/get_edu_user',
    method: 'get',
    params
  })
}

// 保存考试信息
export const save = (data) => {
  return instance({
    url: '/api/v1/test/create',
    method: 'post',
    data
  })
}

// 获取考试信息详情
export const getInfo = (params) => {
  return instance({
    url: '/api/v1/test/detail',
    method: 'get',
    params
  })
}