<template>
  <div>
    <el-dialog title="选择试卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-form-item label="组卷类型:">
              <el-select v-model="form.module"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in $parent.paperTypeList"
                           :key="item.module_id"
                           :label="item.module_name"
                           :value="item.module_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label="试卷科目">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in $parent.subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label="关键字:">
              <el-input placeholder="请输入"
                        v-model="form.keyword"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-button type="primary"
                       @click="search">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="list"
                style="width:100%">
        <el-table-column prop="edu_paper_id"
                         label="ID"
                         width="100" />
        <el-table-column prop="paper_name"
                         label="试卷名称" />
        <el-table-column prop="subject_name"
                         label="科目"
                         width="100" />
        <el-table-column prop="template_name"
                         label="模板名称" />
        <el-table-column prop="paper_score"
                         label="总分"
                         width="100" />
        <el-table-column prop="sheet_status"
                         width="100"
                         label="答题卡状态">
          <template slot-scope="{row}">
            {{paper_correct_type==0?$sheetStatus[row.sheet_status]:$sheetStatus[row.sheet_status2]}}
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         width="100"
                         label="操作">
          <template slot-scope="{row}">
            <el-button type="primary"
                       @click="submit(row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination ref="pagination"
                  :get-data="initData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="totalElements" />
    </el-dialog>
  </div>
</template>

<script>
import { getPaperList, getQuestionNumber } from '@/api/exam.js'
export default {
  data () {
    return {
      dialogVisible: false,
      paper_correct_type: 0,
      list: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keyword: '',
        module: ''
      }
    }
  },
  methods: {
    handleClose () {
      this.resetForm()
      this.dialogVisible = false
    },
    async initData (page, limit) {
      if (this.form.module == '' || this.form.subject_id == '') {
        return this.$message.warning("请选择组卷类型-科目")
      }
      let params = Object.assign({ page, limit }, this.form)
      const { data } = await getPaperList(params)
      this.list = data.list
      this.totalElements = data.count
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    resetForm () {
      this.$refs.formRef.resetFields()
    },
    async submit (row) {
      console.log('row', row)
      let params = {
        edu_paper_id: row.edu_paper_id
      }
      const { data } = await getQuestionNumber(params)
      this.$parent.setQN(data)
      this.$parent.list = [JSON.parse(JSON.stringify(row))]
      this.$parent.paper_module = this.form.module
      this.handleClose()
    },
    getNames (item) {
      let str = ''
      item.paper_correct_data.forEach(item => {
        str += item + ","
      });
      str.trimRight(",")
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>